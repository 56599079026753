import React from "react"
import styled from "@emotion/styled"
import { space, typography } from "styled-system"

const StyledIcon = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${space}
  ${typography}
`

export default function ButtonIcon({ icon }) {
  return <StyledIcon ml={2}>{icon}</StyledIcon>
}
