import styled from "@emotion/styled"
import React from "react"
import { typography, space } from "styled-system"

import pcd from "../images/pcd_logo.svg"

import Link from "./link"
import Nav from "./nav"

const LOGO_SIZE = "2rem"

const StyledHeader = styled.header`
  ${typography}
  ${space}
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
`

const StyledH1 = styled.h1`
  margin: 0;
`

const StyledLink = styled(Link)``

const StyledLogo = styled.img`
  width: ${LOGO_SIZE};
  height: ${LOGO_SIZE};
`

const Header = props => (
  <StyledHeader {...props} pb={3}>
    <StyledH1>
      <StyledLink to="/">
        <StyledLogo alt="Patrick Carroll Design logo" src={pcd} />
      </StyledLink>
    </StyledH1>
  </StyledHeader>
)

export default Header
