/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import styled from "@emotion/styled"
import { ThemeProvider } from "emotion-theming"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { space } from "styled-system"

import { themes, THEME_DARK, THEME_LIGHT } from "../themes/themes"

import Context from "./context"
import Footer from "./footer"
import GlobalStyle from "./globalStyle"
import Header from "./header"
import SEO from "./seo"

const settingNames = {
  theme: "patrick-carroll-theme",
}

const StyledLayout = styled.div`
  background-color: ${({ theme }) => theme.color.neutral};
  max-width: 640px;
  margin: 0 auto;
  ${space}
`

const StyledHeader = styled(Header)`
  ${space}
`

const StyledMain = styled.main`
  ${space}
`

const StyledFooter = styled(Footer)`
  ${space}
`

const Layout = ({ children }) => {
  const [themeKey, setThemeKey] = useState(THEME_LIGHT)

  React.useEffect(() => {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      setThemeKey(THEME_DARK)
    }
  }, [])

  return (
    <Context.Provider
      value={{
        activeThemeName: themeKey,
        theme: themes[THEME_LIGHT],
      }}
    >
      <ThemeProvider theme={themes[themeKey]}>
        <SEO title="Home" />
        <GlobalStyle />
        <StyledLayout p={3}>
          <StyledHeader pb={4} />
          <StyledMain>{children}</StyledMain>
          <StyledFooter pt={3} />
        </StyledLayout>
      </ThemeProvider>
    </Context.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
