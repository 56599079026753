import { darken, lighten } from "polished"

export const THEME_LIGHT = "THEME_LIGHT"
export const THEME_DARK = "THEME_DARK"

const color = {
  brand: {
    primary: "#2295c9",
  },
  light: {
    neutral: "#f7f9fa",
  },
  dark: {
    neutral: "#272c2e",
  },
}

const fontWeight = {
  bold: 700,
  regular: 400,
  light: 200,
}

const baseTheme = {
  fontWeight,
}

export const themes = {
  [THEME_LIGHT]: {
    ...baseTheme,
    color: {
      brand: color.brand.primary,
      neutral: color.light.neutral,
      reverse: color.dark.neutral,
      reverseMuted: lighten(0.45, color.dark.neutral),
      reverseLighter: lighten(0.75, color.dark.neutral),
      reverseLighterMuted: lighten(0.8, color.dark.neutral),
    },
  },
  [THEME_DARK]: {
    ...baseTheme,
    color: {
      brand: color.brand.primary,
      neutral: color.dark.neutral,
      reverse: color.light.neutral,
      reverseMuted: darken(0.45, color.light.neutral),
      reverseLighter: darken(0.75, color.light.neutral),
      reverseLighterMuted: darken(0.8, color.light.neutral),
    },
  },
}
