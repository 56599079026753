import React from "react"
import styled from "@emotion/styled"
import { space } from "styled-system"

const StyledButton = styled(props => <button {...props} />)`
  color: ${({ theme }) => theme.color.reverse};
  outline: none;
  border: 1px solid transparent;
  background-color: ${({ theme }) => theme.color.reverseLighter};
  width: ${({ fill }) => (fill ? "100%" : "auto")};
  display: flex;
  place-items: center;
  ${space}

  &:hover {
    background-color: ${({ theme }) => theme.color.reverseLighterMuted};
    border: 1px solid ${({ theme }) => theme.color.reverse};
  }
`

export default ({ children, ...props }) => (
  <StyledButton {...props} p={2}>
    {children}
  </StyledButton>
)
