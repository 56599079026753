import styled from "@emotion/styled"
import React from "react"

import ThemeButton from "./themebutton"

const StyledFooter = styled.footer``

export default function Footer(props) {
  return <StyledFooter {...props}>{/* <ThemeButton /> */}</StyledFooter>
}
