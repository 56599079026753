import React from "react"
import { Global } from "@emotion/core"
import { css } from "@emotion/core"
import { useTheme } from "emotion-theming"

const FONT_FAMILY_HELVETICA_NEUE = "Helvetica Neue"
const FONT_FAMILY_PRIMARY = FONT_FAMILY_HELVETICA_NEUE
const FONT_FAMILY_FALLBACK = FONT_FAMILY_HELVETICA_NEUE

export default () => {
  const theme = useTheme()

  return (
    <Global
      styles={css`
        body {
          padding: 0;
          margin: 0;
          font-family: "${FONT_FAMILY_PRIMARY}", ${FONT_FAMILY_FALLBACK};
          font-weight: ${theme.fontWeight.regular};
          background-color: ${theme.color.neutral};
          color: ${theme.color.reverse};
          font-size: 14px;
          line-height: 1.3rem;
          max-width: 1920px;
        }
        h1 {
          font-size: 1.3rem;
        }
        h1,
        h2,
        h3,
        h4,
        h5 {
          color: ${theme.color.reverse};
          font-weight: ${theme.fontWeight.bold};
        }
        h5 {
          font-size: 0.8rem;
          font-weight: ${theme.fontWeight.bold};
          margin-bottom: 0.5rem;
        }
        h1:first-of-type {
          margin-top: 0;
        }
        p {
          max-width: 38rem;
        }
        h1 + p {
          margin-bottom: 0;
        }
        @media (prefers-color-scheme: dark) {

        }
        @media (prefers-color-scheme: light) {}
      `}
    />
  )
}
