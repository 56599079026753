import React from "react"
import { Match } from "@reach/router"
import { space } from "styled-system"
import styled from "@emotion/styled"
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai"

import projects from "../data/projects"

import Button from "./button"
import ButtonIcon from "./buttonicon"
import Link from "./link"

const isActive = matchVal => ({
  else: elseVal => ({ match }) => (match ? matchVal : elseVal),
})

const StyledNav = styled.nav`
  ul {
    padding: 0;
    margin: 0;
  }

  ul li {
    display: block;
    font-size: 2rem;
    line-height: 2rem;
  }

  ul li a {
    text-decoration: none;
  }
`

const StyledMenuContainer = styled.div`
  ${space}
  position: absolute;
  box-sizing: border-box;
  top: 48px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: ${({ theme }) => theme.color.neutral};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  pointer-events: ${({ isOpen }) => (isOpen ? "auto" : "none")};
  transition: opacity 0.2s;
`

const StyledLink = styled(Link)`
  display: block;
  color: ${({ theme, match }) =>
    isActive(theme.color.reverseMuted).else(theme.color.reverse)({
      match,
    })};
  /* font-weight: ${({ theme, match }) =>
    isActive(theme.fontWeight.bold).else(theme.fontWeight.light)({ match })}; */
    font-weight: ${({ theme }) => theme.fontWeight.light};
  ${space}
`

const StyledButtonContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
`

const NavLink = ({ to, children }) => (
  <Match path={`/${to}`}>
    {({ match }) => (
      <li>
        <StyledLink to={`/${to}`} match={match} py={1}>
          {children}
        </StyledLink>
      </li>
    )}
  </Match>
)

export default function Nav(props) {
  const [isOpen, setIsOpen] = React.useState(false)
  const icon = isOpen ? <AiOutlineMinus /> : <AiOutlinePlus />
  return (
    <>
      <StyledButtonContainer>
        <Button onClick={() => setIsOpen(!isOpen)}>
          Patrick Carroll
          <ButtonIcon icon={icon} />
        </Button>
      </StyledButtonContainer>
      <StyledMenuContainer p={3} isOpen={isOpen}>
        <StyledNav>
          <h5>Design Work</h5>
          <ul>
            {Object.entries(projects).map(([name, { path }]) => (
              <NavLink key={path} to={path}>
                {name}
              </NavLink>
            ))}
          </ul>
          <h5>External Links</h5>
          <ul>
            <StyledLink
              external
              key="linked"
              to="https://www.linkedin.com/in/patrick-carroll-06806911"
            >
              LinkedIn
            </StyledLink>
            <StyledLink
              external
              key="polywork"
              to="https://polywork.com/patrickcarroll"
            >
              Polywork
            </StyledLink>
            <StyledLink external key="github" to="https://github.com/pdcarroll">
              GitHub
            </StyledLink>
          </ul>
        </StyledNav>
      </StyledMenuContainer>
    </>
  )
}
