import styled from "@emotion/styled"
import { Link as GatsbyLink } from "gatsby"
import React from "react"

const isActive = matchVal => ({
  else: elseVal => ({ match }) => (match ? matchVal : elseVal),
})

const StyledLink = styled(GatsbyLink)`
  color: ${({ theme }) => theme.color.reverse};
  transition: color 0.2s ease-out;
  text-decoration: ${({ match }) =>
    isActive("none").else("underline")({ match })};
  transition: opacity 0.2s;

  &:hover {
    text-decoration: none;
  }

  &:active {
    color: ${({ theme }) => theme.color.brand};
  }
`

const StyledExternal = styled(StyledLink)``.withComponent("a")

export default ({ children, external, ...props }) =>
  external ? (
    <StyledExternal
      {...props}
      href={props.to}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </StyledExternal>
  ) : (
    <StyledLink {...props}>{children}</StyledLink>
  )
